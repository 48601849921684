import '../admin/js/helpers.js';
import '../admin/js/template-customizer.js';
import '../admin/js/config.js';
// import { createPopper } from '@popperjs/core';
import 'bootstrap';
import $ from 'jquery';
import 'node-waves/dist/waves.js';
import '../admin/js/perfect_scrollbar';
import '../admin/js/notification.js';
import '../admin/js/flatpickr.js';
import '../admin/js/menu.js';
import '../admin/js/main.js';
import '../admin/js/tinymce_editor';
import '../admin/js/dropify';
import '../admin/js/sortable.js';

window.$ = $;
