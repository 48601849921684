import 'dropify/dist/js/dropify';

document.addEventListener('DOMContentLoaded', function() {
  var drEvent = $('.dropify').dropify({
    messages: {
      default: '拖拉檔案到這裡，或點擊本區塊',
      replace: '拖拉新檔案或再次點擊，可替換其他檔案',
      remove: '移除',
      error: '出錯了，請重新整理頁面'
    },
    error: {
      'fileSize': '檔案太大超過 {{ value }}',
      'imageFormat': '僅支援格式為 ({{ value }} 的檔案).',
      'fileExtension': '僅支援格式為 ({{ value }} 的檔案).'
    }
  });

  drEvent.on('dropify.afterClear', function(event, element){
    var attr_name = element.input.attr('name').substring(
      element.input.attr('name').lastIndexOf("[") + 1,
      element.input.attr('name').lastIndexOf("]")
    );
    $('<input>').attr({
      type: 'hidden',
      id: 'destroy_' + attr_name,
      name: 'destroy_' + attr_name,
      value: 'true'
    }).appendTo('form');
  });
});
