import Sortable from 'sortablejs/Sortable';

document.addEventListener('DOMContentLoaded', function() {
  let sortTables = $('.sortable-items');
  if (sortTables.length) {
    sortTables.each(function() {
      let url = this.dataset.sortPath;
      Sortable.create(this, {
        group: 'sortList',
        handle: '.drag-handle',
        animation: 150,
        onEnd: function(event) {
          let id = event.item.dataset.item.split('_').pop();
          let data = new FormData();
          data.append("id", id);
          data.append("from", event.oldIndex);
          data.append("to", event.newIndex);
          console.log(`id: ${id}, from: ${event.oldIndex}, to: ${event.newIndex}`);
          fetch(url, {
            method: 'PATCH',
            body: data,
            headers: {
              'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            }
          })
          .then(response => {
            if (!response.ok) {
              throw new Error('網絡回應不正確');
            }
            return response.json();
          })
          .then(data => {
            if (data.status === 'success') {
              window.notify.success(data.message);
            } else {
              window.notify.error(data.message);
            }
          })
          .catch(error => {
            console.error('錯誤:', error);
            window.notify.error('發生錯誤，請稍後再試。');
          });
        }
      });
    });
  }
});
